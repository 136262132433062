import React from "react";
import { graphql } from 'gatsby';
import Mec from "../../templates/MikaMecTemplate";

export default function MecPage({ data: { page, allProducts } }) {
    const productsByLocale =  allProducts.edges.filter(
		(item) => item.node.locale.locale === "sv_SE"
    );
    
	return <Mec page={page} allProducts={productsByLocale} locale="sv" />
}

export const query = graphql`
	fragment BackgroundImage on File {
		childImageSharp {
			resize(height: 250) {
				src
			}
		}
	}
	query {
		page: wpPage(slug: { eq: "vad-mec-egentligen-ar" }) {
			content
			title
			seo {
				metaDesc
				title
			}
		}
		allProducts: allWpProduct {
			edges {
				node {
					locale {
						locale
					}
					productData {
						productImage {
							localFile {
								childImageSharp { 
									gatsbyImageData(height: 250)
								}
							}
						}
					}
				}
			}
		}
	}
`;
